import React from "react"
import placeStyles from "./place.module.scss"
import Button from "./button"

export default function Place(props) {
  
  const background = props.photo

  return (
    <div className={placeStyles.card}>
      <div className={placeStyles.row}>
        <div 
          className={placeStyles.image} 
          alt={props.title} 
          style={{backgroundImage: "url(" + background + ")"}}>
        </div>
        <div className={placeStyles.titleBlock}>
          <h3 className={placeStyles.title}>{props.title}</h3>
          <p className={placeStyles.description}>{props.description}</p>
          <Button url="https://www.ausoniahungaria.com" label="Visit Website"/>
        </div>
     </div>
    </div>
  )
}
