import React from "react"
import Layout from "../components/layout"
import Place from "../components/place"
import Button from "../components/button"
import Helmet from "react-helmet"

export default function Venice() {

  return (
    <Layout>
      <Helmet>
        <title>Cinematic Sights And Vibes On Lido, Venice's Alternative Destination</title>
        <meta property="og:title" content="Cinematic Sights And Vibes On Lido, Venice's Alternative Destination" />
        <meta property="og:description" content="Stunning ferry rides, a newly renovated historical hotel and almost no other tourists in Venice." />
        <meta property="og:image" content="/images/venice/cover-venice.jpeg" />
      </Helmet>
      <section className="layout-fixed">
        <h1>Cinematic Sights And Vibes On Lido, Venice's Alternative Destination</h1>
        <p className="secondary">January 2020</p>
      </section>
      <section className="layout-fluid">
        <img src="/images/venice/cover-venice.jpeg" alt="White Tesla Mode 3 in front of the white Palazzo del Cinema on Venice Lido."></img>
      </section>
      <section className="layout-fixed">
        <p className="lead">A city that's largely inaccessible by car doesn't sound like the best destination for a Tesla trip. However Venice was on top of our places to visit in Italy. It's also highly recommended to visit in low season as the summer season brings cruise ships that flood the city with tourists.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000454.jpg" alt="Overlooking the Grand channel of Venice."></img>
          <p className="photo-caption">A famous view: Venice's Grand Channel.</p>
        </div>
      </section>
      <section className="layout-fixed">
        <h2>Venice in January</h2>
        <p>As the trip took place in January the only floods to be aware off was the 'Acqua Alta' that could set Saint Mark's square under water. We didn't have to worry about our Model 3 - nickname Stormtrooper - getting wet since we were going to stay on Venice's largest island Lido which isn't vulnerable for high tides.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l photogrid-spacing">
          <img src="/images/venice/L1000364.jpg" alt="Two friends have a walk on San Marco square that is lit with golden Christmas decoration. There are few other people."></img>
        </div>
        <div className="photogrid-pp photogrid-spacing">
          <img src="/images/venice/L1000251.jpg" alt="An alley with a cosy restaurent. People having a good time."></img>
          <img src="/images/venice/L1000249.jpg" alt="A glass of red wine next to a candle in a cosy restaurant."></img>
        </div>
        <div className="photogrid-l photogrid-spacing">
          <img src="/images/venice/L1000260.jpg" alt="The inner city canals at night."></img>
        </div>
        <div className="photogrid-pp photogrid-spacing">
          <img src="/images/venice/L1000310.jpg" alt="Two friends standing in front of the window of a beautifully decorated clothing shop."></img>
          <img src="/images/venice/L1000379.jpg" alt="Portrait in front of the San Marco galleries, decorated with Christmas lights."></img>
        </div>
        <p className="photo-caption">Empty squares, cosy restaurants all beautifully lit.</p>
      </section>
      <section className="layout-fixed">
        <p>Something that caught us by surprise though was the sudden rush of fog that would cover the city in just a few minutes. When this happens you can expect delays in public transport on the water which could influence your travel schedule. So make sure to check the weather forecast for fog. We found ourselves waiting for about one hour to be able to get back to Lido from Venice city by water taxi.
</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l photogrid-spacing">
          <img src="/images/venice/L1000510.jpg" alt="A Venice water taxi station and a boat passing by. It's foggy."></img>
        </div>
        <div className="photogrid-l photogrid-spacing">
          <img src="/images/venice/L1000518.jpg" alt="A foggy canal of Venice with a red house."></img>
        </div>
        <p className="photo-caption">Fog can suddently cover the city that was just minutes ago lit by winter sun.</p>
      </section>
      <section className="layout-fixed">
        <h2>Road to Venice</h2>
        <p>Our starting point for every experience trip <a href="https://www.tesla.com/findus?bounds=45.52343719266502%2C12.519974752441403%2C45.340813992582575%2C12.13202004052734&zoom=12&filters=destination%20charger&search=Venice%2C%20Metropolitan%20City%20of%20Venice%2C%20Italy&location=dc79911x" title="Tesla's destination charger map" target="_blank">Tesla's destination charger map</a>. Moving the map to Venice and filtering for destination chargers, it revealed a few hotels on Italy's main land. But our attention was draw towards the only hotel offering a destination charger on its largest island Lido: The Grande Albergo Ausonia &amp; Hungaria.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/destinationchargers-venice.png" alt="Screenshot Tesla destination charger map in Venice."></img>
          <p className="photo-caption">There are only a few destination chargers in Venice.</p>
        </div>
      </section>
      <section className="layout-fixed">
        <p>Next we plan our trip with <a href="https://abetterrouteplanner.com/" title="A Better Route Planner" target="_blank">A Better Route Planner</a>. With its smart algorithm tailered to your EV, it can predict your consumption and proposes charging stops.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/abetterrouteplanner-zurich-venice.png" alt="Screenshot of a better route planner"></img>
          <p className="photo-caption">With its smart algorithm tailered to your EV, abetterrouteplanner.com can predict your consumption and proposes charging stops.</p>
        </div>
      </section>
      <section className="layout-fixed">
        <p>Since the hotel hosts a Tesla destination charger, it would be ideal to arrive with a Low Battery to fully benefit from the free charge. We live in Zürich and with 560 km (348 miles) drive ahead of us, the car was scheduled to be fully charged by 7:00. We grabbed breakfast at a gas station (no other option unfortunately) and enjoyed our morning coffee with the rising sun as our North Star, lighting up the snow dipped mountain peaks of the Gotthard massive.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000065.jpg" alt="View within the Tesla from the back seat. Passengers drink a coffee. Snowy mountain landscape in Switzerland."></img>
          <img src="/images/venice/L1000044.jpg" alt="Snowy mountain landscape in Switzerland."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <p>The Gotthard road tunnel is 17km long and with maximum speed of 80 km/h it can be a frustrating piece of the trip. Especially when someone ahead is not driving cruise control or going 60 km/h. Not with the Model 3's distance control and auto steer. This makes it easy to maintain the recommenced safety distance. The Gotthard tunnel became a more enjoyable experience with the Model 3. Can you imagine how clean the air would be in tunnels if it were all zero emission vehicles?</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000017.jpg" alt="Driving through the Gotthard tunnel with auto pilot visible on the Tesla display."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <p>The Better Route Planner advised us to charge one time but we decided to make two stops just be sure. New Tesla owners have typically more range anxiety and with us it wasn't different. Honestly the range anxiety wasn't necessary.
        </p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000094.jpg" alt="The Tesla super charger in Melide."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <p>At the southern tip of Switzerland, just past Lugano, we made our first stop at the Melide Supercharger. It features wide views over the bay of Lugano and it sits next to the Swiss Miniature Park. Perfect if you want to take a longer break when you're traveling with kids. In 20 minutes we charged 40% for 9 CHF (~9 USD), ready to enter Italy.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000130.jpg" alt="The Tesla super charger in Breccia."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <p>Lunch time grew closer and our next charging spot came at the right time. The Breccia Supercharger sits on the parking lot of the Elnòs Shopping Center. Good side is the abundance of food options. You could even go for an Ikea lunch! The downside is the location of the supercharger on the parking lot. You have to cross it first which means queuing on busy shopping days which we experienced on the way back on a Saturday evening. For a quick healthy lunch we recommend Cooofe. We had to finish lunch quickly as the Supercharger was powering at its full capacity (100 - 150 kW). In about half an hour we charged from 25% to 90% but because of some technical issue, it costed us ... nothing!</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000139.jpg" alt="A lunch restaurant offering Italian pastery."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <h2>Panoramic Ferry</h2>
        <p>Crossing the bridge and seeing Venice rising on the horizon is a magical experience. It became even better taking the Ferry through the city center with beautiful sights on the landmarks. These sights you only get when your car is first on the ferry. Therefore its recommend to reserve your ticket for a fixed time.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000156.jpg" alt="Two full rows of queueing cards while the Tesla is standing alone in one of the reservation lanes."></img>
          <img src="/images/venice/L1000151.jpg" alt="Driving waiting in the Tesla."></img>
          <img src="/images/venice/L1000162.jpg" alt="Driving on the Ferry."></img>
          <video width="100%" controls>
            <source src="/videos/venice-ferry.MOV" type="video/mp4" />
          Your browser does not support playing this video.
        </video>
        </div>
      </section>
      <section className="layout-fixed">
        <p>
          We reserved the ferry for 15:00 and were lucky to have no other one reserving. A return ticket costs 52 euro (+ 4 euro commission). We got a coffee and pastry at the Cooofe, turned on the seat heating and opened the windows to take in Venice from this unique perspective. After about half an hour, the ferry's front gate opened and our tires touched down on the roads of Lido leading us to our hotel.</p>
      </section>
      <section className="layout-fluid">
        <img src="/images/venice/L1000206.jpg" alt="View on Venice from the Ferry."></img>
        <p className="photo-caption">Seat heating on and windows open to take in this incredible view.</p>
      </section>
      <section className="layout-fixed">
        <h2>Recharging at Lido</h2>
        <p>The Grande Albergo Ausonia &amp; Hungaria is a five star lifestyle and wellness hotel founded in 1913 and in 2019 completely renovated inside. The rooms are bright, clean and reflect the round shapes of the hotel. From outside the hotel is easy to spot as its eye catching multi coloured facade designed by ceramist Luigi Fabris is still its main attraction. When it comes to amenities it features a (small) spa, rooftop bar and outdoor swimming pool.</p>
      </section>
      <section className="layout-fixed">
        <Place title="Hotel Ausonia Hungaria" description="Five star hotel on Venice Lido" photo="/images/venice/hotel-ausonia-hungaria.jpg" />
      </section>
      <section className="layout-expanded">
        <img src="/images/venice/L1000534.jpg" alt="White Tesla Model 3 in front of hotel."></img>
        <p className="photo-caption">Our destination: The Grande Albergo Ausonia &amp; Hungaria.</p>
      </section>
      <section className="layout-fixed">
        <p>On arrival, the battery's charge was now at 40% which is a bit higher that we would like but the Breccia's Supercharger's speed was to blame. The Destination Charger is right next to the hotel and the hotel personal can move it when a fellow Tesla needs some juice. We were lucky it was calm and during our whole stay our Stormtrooper stayed attached to the charger. The charge experience at the hotel was perfect.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-lp">
          <img src="/images/venice/L1000220.jpg" alt="View on Lido beach."></img>
          <img src="/images/venice/L1000230.jpg" alt="White Tesla Model 3 in front of Lido beach."></img>
        </div>
        <p className="photo-caption">Modern rooms and a view on quiet Lido.</p>
      </section>
      <section className="layout-fixed">
        <p>Inside the hotel reveals its social history. The hallways are decorated with photos from the annual Venice Film festival which is hosted on Lido. Every year Lido attracts many famous actors and directors and some stay in or visit the hotel. The 76th Venice Film Festival brought Johnny Depp, Spike Lee, Paolo Sorrentino, Gabriele Salvatores, John Malkovich and Jude Law according to this journal.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l photogrid-spacing">
          <img src="/images/venice/L1000590.jpg" alt="Beautifully decorated hotel room with a mural of a classic statue."></img>
        </div>
        <div className="photogrid-l">
          <img src="/images/venice/L1000558.jpg" alt="View from hotel with old buildings on the skyline."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <p>The annual event is hosted in Palazzo del Cinema. It's right next to Lido's beaches which it's know for in summer while wintertime brings quietness to the island. The bright stones of the Palazzo are beautiful on a sunny day for photoshoots. It triggers a cinematic vibe. There we took our best photo yet of our Stormtrooper.</p>
      </section>
      <section className="layout-expanded">
        <div className="photogrid-l">
          <img src="/images/venice/L1000566.jpg" alt="Jip and Lorenz standing in front of the Palazzo del Cinema on Venice Lido."></img>
        </div>
      </section>
      <section className="layout-fixed">
        <h2>Parking in the city</h2>
        <p>From Lido, Venice's historical city center is super easy to reach with public transport for which we took a 3 days pass. After checking out on the third day, we decided to stick around and enjoy the afternoon in Venice while leaving the car in the San Marco Parking which is Venice's largest parking closest to the city center. Since it hosts a destination charger as well we thought it was Tesla friendly.</p>
      </section>
      <section className="layout-fluid">
        <img src="/images/venice/L1000614.jpg" alt="Beautiful panoramic view over Venice during sunset." ></img>
        <p className="photo-caption">We don't recommend the parking but the view from the top floor is worth a picture.</p>
      </section>
      <section className="layout-fixed">
        <p>We didn't reserve the parking so they told us that the destination charger would not be available. No problem since we got a nearly full charge for the way back anyway. However it was busy and we had to park on the 8th floor. No problem right? Well apparently the parking is designed for Fiat 500's. The spiral road going up is super narrow and totally not designed for big cars such as the Model 3.</p>
      </section>
      <section className="layout-expanded">
        <img src="/images/venice/L1000621.jpg" alt="White Tesla Model 3 parked on the roof of the parking garage in Venice."></img>
        <p className="photo-caption">Leave the car unlocked they said.</p>
      </section>
      <section className="layout-fixed">
        <p>If you have a Model X or Model S, please forget about it unless you want to scratch your rims. We made it in and out with the sweat on our backs and no scratches. But we had to take it extremely slow. Also the parking is made for maximum revenue so on the 8th floor we had to park double meaning leaving the car unlocked so it can be moved by the staff. We didn't like this at all since all our bags were in the car. So our advice, don't park your car in San Marco Parking and instead park with your hotel or Mestre.</p>
      </section>
      <section className="layout-fluid">
        <img src="/images/venice/L1000617.jpg" alt="Sunset silhouettes on the parking garage rooftop."></img>
      </section>
      <section className="layout-fixed">
        <h2>Conclusion</h2>
        <p>It was our first time in Venice and we feel lucky to have seen it with a minimum amount of tourists and sunny weather. We explored Lido and could drive all the way up to our hotel in the comfort of our Model 3 and get a free charge on top!</p>
      </section>
      <section className="layout-fluid">
        <img src="/images/venice/L1000609.jpg" alt="Cafe Florian at San Marco. You can see Jip, Lorenz and Friend in the reflection of the window."></img>
      </section>
      <section className="layout-fixed">
        <h2>Links</h2>
        <p><ul><li><a target="_blank "href="http://actv.avmspa.it/en">Venice Ferry Tickets</a></li>
          <li><a target="_blank "href="https://abetterrouteplanner.com">A Better Route Planner</a></li></ul></p>
      </section>
      <section className="layout-fixed">
        <h2>Consumption</h2>
        <p>Full consumption overview:</p>
        <table className="consumption">
          <tbody>
            <tr>
              <th>Charge</th>
              <th>kWh</th>
              <th>Amount</th>
            </tr>
            <tr>
              <td>Home</td>
              <td>75 kWh</td>
              <td>13.50 CHF</td>
            </tr>
            <tr>
              <td>Melide Super Charger</td>
              <td>30 kWh</td>
              <td>9 CHF</td>
            </tr>
            <tr>
              <td>Brescia Super Charger</td>
              <td>49 kWh</td>
              <td>0 EUR (payment error)</td>
            </tr>
            <tr>
              <td>Hotel</td>
              <td>32.7 kWh</td>
              <td>Free</td>
            </tr>
            <tr>
              <td>Brescia Super Charger</td>
              <td>51 kWh</td>
              <td>0 EUR (payment error)</td>
            </tr>
            <tr>
              <td>Melide Super Charger</td>
              <td>14 kWh</td>
              <td>4.20 CHF</td>
            </tr>
            <tr>
              <td>Quinto Super Charger</td>
              <td>22 kWh</td>
              <td>6.38 CHF</td>
            </tr>
            <tr className="total">
              <td>Total:</td>
              <td>245 kWh	</td>
              <td>33.08 CHF</td>
            </tr>
          </tbody>
        </table>
        <p>
          <Button url="https://teslascope.com/vehicle/jip/Stormtrooper/" label="View on Tesla Scope" />
        </p>
      </section>

    </Layout>

  )
}